import { useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Container } from 'react-bootstrap';
import moment from 'moment';

import { use } from '../../redux/factory';
import {
  EditButton,
  HideButton,
  ShowButton,
  CreateEditModal as EditModal,
  DeleteConfirmButton,
  DataTable,
  useLocalStorage,
} from '../shared/ReactToolbox';
import { updateFormFields } from './ProjectView';
import TaskTemplateBadge from '../production/TaskTemplateBadge';
import TeamleaderClient from '../teamleader/TeamleaderClient';
import SelectTaskTemplates from './SelectTaskTemplates';
import {
  Project,
  TeamleaderCustomFieldDefinitionsList,
  SettingsList,
  ProjectTask,
} from '../../redux/types';
import { hasStarted } from './ProjectView';

export type ProjectsDataTableProps = {
  projects: Project[],
  additionalActions: ({ project }: { project: Project }) => ReactElement,
}

const ProjectsDataTable = ({
   projects,
   additionalActions: AdditionalActions,
}: ProjectsDataTableProps) => {

  const { moveProject, updateProject, deleteProject, showProject, hideProject, projectsList } = use.projects();
  const { teamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions() as { teamleaderCustomFieldDefinitionsList: TeamleaderCustomFieldDefinitionsList };
  const { teamleaderDealPhasesList } = use.teamleaderDealPhases();
  const { getTeamleaderDeal } = use.teamleaderDeals();
  const { productionTemplatesList } = use.productionTemplates();
  const { taskTemplatesList } = use.taskTemplates();
  const { settingsList } = use.settings() as { settingsList: SettingsList};
  const [projectInEditModal, setProjectInEditModal] = useState<Project | null>(null);
  const [selectedTaskTemplates, setSelectedTaskTemplates] = useLocalStorage<number[]>('selectedTaskTemplates', []);

  const cfd = settingsList.teamleader_custom_field_definitions_in_projects_table;
  const tcfdl = teamleaderCustomFieldDefinitionsList

  const columns = [
    {
      name: '#',
      orderBy: ({ order }: Project) => order,
      selector: ({ order }: Project) => order + 1,
    },
    {
      orderBy: ({ teamleader_deal: td }: Project) => td.contact && td.contact.last_name,
      name: 'Bedrijfsentiteit',
      selector: ({ teamleader_deal: td }: Project) => td.department
        ? <a href={td.data.web_url} target='_blank' rel='noreferrer'>{td.department.name}</a>
        : <i>Niet gevonden</i>
    },
    {
      orderBy: ({ teamleader_deal: td }: Project) => td.contact && td.contact.last_name,
      name: 'Klant',
      selector: ({ teamleader_deal: td }: Project) => <TeamleaderClient teamleaderDeal={td} />,
    },
    {
      orderBy: 'name',
      name: 'Naam',
      selector: 'name',
    },
    {
      orderBy: ({ teamleader_deal: td }: Project) => td.data.reference,
      name: 'Dealnummer',
      selector: (project: Project) => project.teamleader_deal && <>
        <Link to={`/projects/${project.id}`}>
          {project.teamleader_deal.data.reference || <i>Niet gevonden</i>}
        </Link>
      </>,
    },
    {
      name: 'Fase',
      orderBy: ({ teamleader_deal: td }: Project) => (
        ((td && teamleaderDealPhasesList[td.phase]) || {}).name || ''
      ),
      selector: ({ teamleader_deal: td }: Project) => {
        const phase = td && teamleaderDealPhasesList[td.phase];
        if (!phase) {
          return <i>Niet gevonden</i>;
        }
        return phase.name;
      },
    },
    {
      name: 'Productietemplate',
      orderBy: ({ production_template }: Project) =>
        productionTemplatesList[production_template]?.name || '',
      selector: ({ production_template }: Project) => {
        const productionTemplate = productionTemplatesList[production_template];
        if (!productionTemplate) return <i>Niet gevonden</i>;
        return productionTemplate.name || `#${productionTemplate.id}`
      }
    },
    {
      name: 'Lopende taken',
      selector: ({ tasks }: Project) => {
        const startedTasks = tasks.filter(hasStarted);
        if (startedTasks.length === 0) return <i>Geen taken</i>;
        return startedTasks.map((task, index) => {
          const taskTemplate = taskTemplatesList[task.task_template];
          if (!taskTemplate) {
            return <Badge bg="warning" key={index}><i>Niet gevonden</i></Badge>
          }
          return (
            <TaskTemplateBadge
              key={index}
              taskTemplate={taskTemplate}
              status={task.status}
            />
          );
        });

      }
    },
    ...cfd.value.reduce((o: any, id: number) => ([
      ...o,
      {
        name: tcfdl[id].label,
        orderBy: ({ teamleader_deal: td }: Project) =>
          (
            td.custom_fields.find((cf: any) => cf.definition === id) || {}
          ).value || '',
        selector: ({ teamleader_deal: td }: Project) => {
          const cf = td.custom_fields.find((cf: any) => cf.definition === id)
          if (!cf || cf.value === null) return <i>Leeg</i>
          return tcfdl[id].type === 'date' ? moment(cf.value).format('DD-MM-YYYY') : cf.value;
        },
      }
    ]), []),
    {
      name: 'Aangemaakt op',
      orderBy: 'created_at',
      selector: ({ created_at }: Project) => moment(created_at).format('DD-MM-YYYY HH:mm'),
    },
    {
      name: 'Acties',
      selector: (project: Project) =>
        <span>
          {AdditionalActions && <AdditionalActions project={project} />}
          {project.hidden
            ? <ShowButton
                onClick={() => showProject(project)}
                loading={showProject.isLoading && showProject.data.id === project.id}
                className='float-end'
                title='Verplaats naar tabblad "Projecten"'
              />
            : <HideButton
                onClick={() => hideProject(project)}
                loading={hideProject.isLoading && hideProject.data.id === project.id}
                className='float-end'
                title='Verbergen'
              />
          }
          <DeleteConfirmButton
            className="float-end"
            onDelete={async () => {
              const teamLoaderDeal = project?.teamleader_deal;
              await deleteProject(project)
              await getTeamleaderDeal(teamLoaderDeal?.id)
            }}
          />
          <EditButton
            className="float-end"
            onClick={() => setProjectInEditModal(project)}
          />
        </span>,
    }
  ];

  return (
    <>
      <Container fluid className='vertical-space'>
        <SelectTaskTemplates
          {...{
            selectedTaskTemplates,
            setSelectedTaskTemplates,
            projects,
          }} />
      </Container>

      <DataTable
        className='vertical-space'
        rowsPerPage={null}
        filterColumn={({ name, teamleader_deal: td, production_template }) => `${
          name
        } ${
          td.data.title
        } ${
          td.company ? td.company.name : ''
        } ${
          td.contact && td.contact.first_name ? td.contact.first_name : ''
        } ${
          td.contact && td.contact.last_name ? td.contact.last_name : ''
        } ${
          td.contact && td.contact.first_name ? td.contact.first_name : ''
        } ${
          productionTemplatesList[production_template] ? productionTemplatesList[production_template].name : ''
        }`}
        columns={columns}
        data={
          selectedTaskTemplates.length > 0
            ? projects.filter(
                ({ tasks }: Project) => tasks.find((task: ProjectTask) =>
                  hasStarted(task)
                  && selectedTaskTemplates.includes(task.task_template)
                )
              )
            : projects
          }
        onMove={({ item, target, reset }) => moveProject(
          {
            project: item,
            target,
            position: item.order > target.order ? 'right' : 'left',
          },
          {
              callback: (
                projects: Project[],
                { setProjectsList }: { setProjectsList: (projects: Project[]) => void }) => {
                setProjectsList(
                  projects
                    .map(({ id, order }) =>
                      projectsList[id] && { ...projectsList[id], order }
                    )
                    .filter(project => !!project)
                );
              },
              onError: reset,
          }
        )}
        orderByDefault='order'
      />
      {projectInEditModal &&
        <EditModal
          onHide={() => setProjectInEditModal(null)}
          modalTitle="Project aanpassen"
          loading={updateProject.isLoading}
          initialState={projectInEditModal}
          // @ts-ignore
          formFields={updateFormFields}
          // @ts-ignore
          onSave={project => updateProject(
            project,
            { callback: () => setProjectInEditModal(null) }
          )}
        />
      }
    </>
  )
}
export default ProjectsDataTable;
